import { Link, M70MediaItem } from '~/lib/data-contract';
import {
    PageViewDataLayer,
    ViewProduct360DataLayer,
    ViewProductDataLayer,
    CountrySelectorDataLayer,
    DownloadProductFileDataLayer,
    ProductLeadDataLayer,
    SocialLinkDataLayer,
    WebinarClickDataLayer,
    WebinarLeadDataLayer,
    CatalogueClickDataLayer,
    CatalogueLeadDataLayer,
    FormLeadDataLayer,
    MenuFormLeadDataLayer,
    SidePanelOpenDataLayer,
    WhitePaperDownloadDataLayer,
    TrackingDataLayer,
    ImageGalleryClickDataLayer,
    VideoPlayDataLayer,
    CategoryFilterDataLayer,
    InternalSearchDataLayer,
    NewsletterFormDataLayer,
    TenderTextFormDataLayer,
    FavoritesConfiguratorDataLayer,
    FavoritesAddToComparisonDataLayer,
    FavoritesProductsComparedDataLayer,
    FavoritesAddToFavoritesDataLayer,
    FavoritesRemoveFromFavoritesDataLayer,
    FavoritesFormLeadDataLayer,
    FavoritesFormTenderTextDataLayer,
    FavoritesShareLinkDataLayer,
    FavoritesOpenLinkDataLayer,
    FormBeginDataLayer,
    OpenConfiguratorDataLayer,
} from '$shared/components';

export enum TrackingEvent {
    PAGE_VIEW = 'pageview',
    VIEW_PRODUCT = 'view_product',
    VIEW_PRODUCT_360 = 'PDP_360view',
    COUNTRY_SELECTOR = 'country_selector',
    PDP_DOWNLOADS = 'PDP_downloads',
    PRODUCT_LEAD = 'product_lead',
    SOCIAL_CLICK = 'social_click',
    WEBINAR_CLICK = 'webinar_click',
    WEBINAR_LEAD = 'webinar_lead',
    CATALOGUE_CLICK = 'catalogue_click',
    CATALOGUE_LEAD = 'catalogue_lead',
    FORM_LEAD = 'form_lead',
    MENU_FORM_LEAD = 'menuform_lead',
    SIDE_PANEL_OPEN = 'sidepanel_open',
    WHITE_PAPER_DOWNLOAD = 'whitepaper_download',
    IMAGE_GALLERY_CLICK = 'imagegallery_click',
    VIDEO_PLAY = 'video_play',
    CATEGORY_FILTER = 'category_filter',
    INTERNAL_SEARCH = 'internal_search',
    NEWSLETTER_FORM_LEAD = 'newsletter_form',
    TENDER_TEXT_FORM_LEAD = 'tendertext_lead',
    FAVORITES_ADD_TO_COMPARISON = 'add_to_comparison',
    FAVORITES_PRODUCTS_COMPARED = 'products_compared',
    FAVORITES_ADD_TO_FAVORITES = 'add_to_favorites',
    FAVORITES_REMOVE_FROM_FAVORITES = 'remove_from_favorites',
    FAVORITES_FORM_LEAD = 'favorites_lead',
    FAVORITES_FORM_TENDER_LEAD = 'favorites_tender_lead',
    FAVORITES_FAVORITES_SHARE_LINK = 'favorites_share_link',
    FAVORITES_FAVORITES_OPEN_LINK = 'favorites_open_link',
    FORM_BEGIN = 'form_begin',
    OPEN_CONFIGURATOR = 'configurator_view',
}

export type UseTrackingModel = {
    getInitialUrl: () => string | undefined;
    getLocationUrl: () => string | undefined;
    getLocationPathname: () => string | undefined;
    trackLink: (link?: Link, headline?: string) => void;
    trackMedia: (media?: M70MediaItem) => void;
    tracking: (trackingDataLayer: TrackingDataLayer) => void;
    trackPageView: (pageViewDataLayer: PageViewDataLayer) => void;
    trackViewProduct: (viewProductDataLayer: ViewProductDataLayer) => void;
    trackViewProduct360: (viewProduct360DataLayer: ViewProduct360DataLayer) => void;
    trackCountrySelector: (countrySelectorDataLayer: CountrySelectorDataLayer) => void;
    trackDownloadProductFile: (downloadProductFileDataLayer: DownloadProductFileDataLayer) => void;
    trackProductLead: (productLeadDataLayer: ProductLeadDataLayer) => void;
    trackSocialLinkClicked: (socialLinkDataLayer: SocialLinkDataLayer) => void;
    trackWebinarClick: (webinarClickDataLayer: WebinarClickDataLayer) => void;
    trackWebinarLead: (webinarLeadDataLayer: WebinarLeadDataLayer) => void;
    trackCatalogueClick: (catalogueClickDataLayer: CatalogueClickDataLayer) => void;
    trackCatalogueLead: (catalogueLeadDataLayer: CatalogueLeadDataLayer) => void;
    trackFormLead: (formLeadDataLayer: FormLeadDataLayer) => void;
    trackMenuFormLead: (MenuFormLeadDataLayer: MenuFormLeadDataLayer) => void;
    trackSidePanelOpen: (sidePanelOpenDataLayer: SidePanelOpenDataLayer) => void;
    trackWhitePaperDownload: (whitePaperDownloadDataLayer: WhitePaperDownloadDataLayer) => void;
    trackImageGalleryClick: (imageGalleryClickDataLayer: ImageGalleryClickDataLayer) => void;
    trackVideoPlay: (videoPlayDataLayer: VideoPlayDataLayer) => void;
    trackCategoryFilter: (categoryFilterDataLayer: CategoryFilterDataLayer) => void;
    trackInternalSearch: (internalSearchDataLayer: InternalSearchDataLayer) => void;
    trackNewsletterForm: (newsletterFormDataLayer: NewsletterFormDataLayer) => void;
    trackTenderTextForm: (tenderTextFormDataLayer: TenderTextFormDataLayer) => void;
    trackFavoritesConfigurator: (
        favoritesConfiguratorDataLayer: FavoritesConfiguratorDataLayer,
    ) => void;
    trackFavoritesAddToComparison: (
        favoritesAddToComparisonDataLayer: FavoritesAddToComparisonDataLayer,
    ) => void;
    trackFavoritesProductsCompared: (
        favoritesProductsComparedDataLayer: FavoritesProductsComparedDataLayer,
    ) => void;
    trackFavoritesAddToFavorites: (
        favoritesAddToFavoritesDataLayer: FavoritesAddToFavoritesDataLayer,
    ) => void;
    trackFavoritesRemoveFromFavorites: (
        favoritesRemoveFromFavoritesDataLayer: FavoritesRemoveFromFavoritesDataLayer,
    ) => void;
    trackFavoritesFormLead: (favoritesFormLeadDataLayer: FavoritesFormLeadDataLayer) => void;
    trackFavoritesFormTenderLead: (
        favoritesFormTenderLeadDataLayer: FavoritesFormTenderTextDataLayer,
    ) => void;
    trackFavoritesShareLink: (favoritesShareDataLayer: FavoritesShareLinkDataLayer) => void;
    trackFavoritesOpenLink: (favoritesOpenDataLayer: FavoritesOpenLinkDataLayer) => void;
    trackFormBegin: (formBeginDataLayer: FormBeginDataLayer) => void;
    trackOpenConfigurator: (openConfigurator: OpenConfiguratorDataLayer) => void;
};

